import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"md":"8","offset-md":"2"}},[_c(VCard,{attrs:{"width":"100%"}},[_c(VRow,{staticClass:"pa-4",attrs:{"justify":"space-between"}},[_c(VCardTitle,[_vm._v("Profil de "+_vm._s(_vm.logged_user.first_name)+" "+_vm._s(_vm.logged_user.last_name))]),_c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-2 mr-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Modifier son profil")])]}}]),model:{value:(_vm.userDialog),callback:function ($$v) {_vm.userDialog=$$v},expression:"userDialog"}},[_c('UserForm',{attrs:{"operation":"update","user":_vm.logged_user},on:{"reloadUsers":_vm.reloadUser}})],1)],1),_c(VCardText,[_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v("Prénom")]),_c('br'),_c('span',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.logged_user.first_name))]),_c('br'),_c('br'),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v("Nom")]),_c('br'),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.logged_user.last_name))]),_c('br'),_c('br'),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v("Email")]),_c('br'),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.logged_user.email))]),_c('br'),_c('br'),_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v("Groupe")]),_c('br'),_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.logged_user.group))]),_c('br'),_c('br')])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }